import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoAngularNgrx extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_core_concepts: file(relativePath: { eq: "courses/courses-logos/angular-cc.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/angular-cc.png" }) { ...imgTrainingBig },

            review_1: file(relativePath: { eq: "courses/courses-reviews/angular-cc/review_anguarcc_1.png" }) { ...imgTrainingBig },
            review_2: file(relativePath: { eq: "courses/courses-reviews/angular-cc/review_anguarcc_2.png" }) { ...imgTrainingBig },
            review_3: file(relativePath: { eq: "courses/courses-reviews/angular-cc/review_anguarcc_3.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-core-concepts-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-core-concepts-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-core-concepts-mode3.png" }) { ...imgMd },
            pricing_bg: file(relativePath: { eq: "courses/pricing/angular-core-concepts.png" }) { ...imgMd },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#dd0031',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#dd0031',
    separatorColor2: '#c3002f',
    buttonColor: '#dd0031',
    panelBackground1: '#E66161',
    iconColor1: '#666',       // summary
    iconColor2: '#dd0031',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso Angular Core Concepts: un corso sulle principali funzionalità di Angular',
    title: '<strong>ANGULAR</strong><br /> CORE CONCEPTS',
    shortTitle: '<strong>ANGULAR</strong><br /> CORE CONCEPTS',
    subtitle: 'Un corso sulle principali funzionalità di Angular per acquisire una solida base sul framework e sviluppare le prime Single Page Applications',
    image: 'angular_core_concepts',
    difficulty: 'Principiante',
    duration: '16 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Un corso sulle principali funzionalità di Angular per acquisire una solida base sul framework e iniziare a sviluppare le prime Single Page Applications: styling, directives, template driven forms, servizi e dependency injection, server-side communication, multi-view applications con Angular Router, una veloce introduzione alla creazione di componenti e all’organizzazione di progetti. <br /><br />Durante il corso sono previste delle brevi esercitazioni e sarà configurato l\'ambiente di lavoro sui laptop dei partecipanti.' },
/*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore (2gg): il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: 'Al termine del corso i partecipanti saranno in grado di creare semplici applicazioni multi-view con angular router, comunicare con il server attraverso REST API, creare template-driven form con validazioni e gestione errori, utilizzare le direttive del framework per manipolare il DOM e creare applicazioni CRUD, sfruttare il motore di dependency injection creando servizi e creare i primi componenti custom riutilizzabili.'},
  ],
  metadata: [
    { title: 'REQUISITI', text: 'Familiarità con almeno un linguaggio di programmazione e, possibilmente, conoscere i concetti fondamentali su cui si basa il paradigma della programmazione ad oggetti. <br /> Aver già utilizzato HTML, CSS e Javascript ES6 sicuramente saranno di grande aiuto.<br /> Se pensi di avere delle carenze su JavaScript e TypeScript, valuta il programma del corso <a href="../corso-javascript-typescript/">Javascript ES6 & Typescript Intro</a>' },
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1'},
      /*{img: 'carouselIntro1', desc: 'Un abuso della dependency injection porta spesso ad uno stato unpredictable'},*/

    ],
    reviews: [
      {img: 'review_1', desc: ''},
      {img: 'review_2', desc: ''},
      {img: 'review_3', desc: ''},
    ],
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16 ore ' },
    { icon: faChartBar, label: 'Livello:', value: 'Principiante' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'Un semplice esempio di codice Angular per il caricamento di dati acquisiti da servizi REST e la loro visualizzazione tramite alcuni degli strumenti inclusi dal framework:',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#c3002f',
    items: [
      { text: 'Direttive del framework: ngIf e ngFor'},
      { text: 'HttpClient e comunicazione con REST API'},
      { text: 'Creazione custom types tramite l\'utilizzo di interfaccce'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Lezioni dedicate alla teoria per comprendere le basi del framework e i suoi costrutti. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'

    },
    colCenter: {
      img: 'mode2',
      title: 'LIVE CODING',
      text: 'La maggior parte del corso è svolto con sessioni di live coding: esempi pratici per la gestione di diversi scenari, casi d\'uso tipici e utilizzo delle API più popolari',

    },
    colRight: {
      img: 'mode3',
      title: 'REAL APPS',
      text: 'Durante il corso sarà sviluppata una semplice Single Page Application per la gestione di operazioni CRUD tramite l\'utilizzo di API RESTful allo scopo di mettere pratica i concetti acquisiti.',
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'Environment & Tools' },
      { value: 'NodeJS, NPM & gestire differenti versioni di NodeJS con NVM' },
      { value: 'Installare un Terminale su Mac e Windows' },
      { value: 'Installare e creare progetti con Angular CLI' },

      { type: 'title', value: 'ANGULAR FUNDAMENTALS' },
      { value: 'Creazione progetti con angular-cli' },
      { value: 'Integrazione framework e librerie CSS: Bootstrap, FontAwesome, …' },
      { value: 'Template Tags' },
      { value: 'Manipolazione DOM' },
      { value: '1-way e 2-way Binding' },
      { value: 'Decorator @Component e metadata' },
      { value: 'Dynamic HTML templates' },
      { value: 'Direttive built-in: *ngIf, *ngSwitch, *ngFor' },
      { value: 'Gestire e manipolare collezioni dati' },

      { type: 'title', value: 'DYNAMIC STYLING in ANGULAR' },
      { value: 'From static to dynamic CSS' },
      { value: 'inline CSS' },
      { value: 'css “unit” suffix' },
      { value: 'ngClass' },
      { value: 'ngStyle' },

      { type: 'title', value: 'DEPENDENCY INJECTION' },
      { value: 'Creazione custom Service Providers' },
      { value: '@Injectable decorator: quando e perché usarlo?' },
      { value: 'Utilizzare i servizi per condividere dati tra routes e componenti' },
      { value: 'Utilizzare i servizi per gestire lo stato dei componenti' },

    ],
    colRight: [

      { type: 'title', value: 'ANGULAR FORMS' },
      { value: 'Keyboard Events' },
      { value: 'Template driven forms' },
      { value: 'Form & Input Template Reference Variables' },
      { value: 'ngModel directive: 1-way vs 2-ways binding' },
      { value: 'ngForm e submit' },
      { value: 'Form validators' },
      { value: 'Form Errors: custom messages in according with the error type' },
      { value: 'form state: dirty, invalid, touch, …' },
      { value: 'Validation with regular expressions' },
      { value: 'Custom validators with Directives' },


      { type: 'title', value: 'INTRODUZIONE AI CUSTOM COMPONENTS' },
      { value: 'Creare Angular Components' },
      { value: 'Template dinamici' },
      { value: 'Passare proprietà ai componenti' },

      { type: 'title', value: 'MULTIVIEW APPLICATIONS' },
      { value: 'Il modulo @angular/router' },
      { value: 'Single Page Application Multiview' },
      { value: 'Router Navigation' },
      { value: 'Feature Route components' },
      { value: 'Condividere dati tra le routes' },


    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16 ore'},
      { label: 'CheatSheet Angular Fundamentals', extra: 'PDF formato A4'},
      { label: 'CheatSheet Javascript ES6', extra: 'PDF formato A4'},
      { label: 'Centinaia di slides', extra: 'Accesso Area Riservata'},
      { label: 'Decine di esercitazioni', extra: 'Accesso Area Riservata'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: 'l-OlhEEwOWs',
    teaser1Text: `
Ciao,
mi chiamo Fabio Biondi, sono Google Developer Expert in web technologies e Angular.
<br /><br />
Angular Core Concepts è un corso per sviluppatori che non hanno mai utilizzato Angular ma che desiderano
in breve tempo acquisire il know-how necessario per realizzare Single Page Applications con uno dei framework front-end
più utilizzati al mondo, soprattutto in ambito corporate ed enterprise.
<br /><br />
Il corso contiene sessioni di teoria in cui descrivo le funzionalità del framework, ma la quasi totalità delle
lezioni è basata su sessioni di live coding in cui descrivo scenari e casi d'uso con esempi reali
per poi affrontare le classiche problematiche che si presentano nella realizzazione di una semplice
applicazione CRUD multiview, simulando la creazione di un semplice CMS.
<br /><br />
Durante il corso descriverò le principali funzionalità dell'ultima release di Angular
affinché i partecipanti possano da subito raggiungere una certa autonomia nella gestione delle
più comuni attività da integrare in una qualunque applicazione web: gestione template e manipolazione DOM,
creazione di form con gestione di validazioni ed errori, comunicazione con REST API,
styling, sfruttare il motore di dependency injection e creazione di applicazioni multiview
con il router integrato nel framework
<br /><br />
Vedremo brevemente anche come organizzazione i template HTML in diversi componenti,
anche se questo argomento è tuttavia affrontato in modo più esaustivo nei gli altri corsi in catalogo.

<br /><br />  
I miei corsi sono sempre tarati sulle skill dei partecipanti e sugli obiettivi della società che lo richiede,
perciò sarà possibile decidere di comune accordo se approfondire o meno alcune tematiche,
se far svolgere  ai partecipanti delle esercitazioni per mettere in pratica i concetti esposti e configurare ad esempio
l'ambiente di lavoro sui propri PC (installando Node, NVM, AngularCli e creando i primi progetti Angular)
oppure propendere per un corso più intensivo decidendo di approfondire, ad esempio, alcune tematiche fondamentali
per il team o il progetto che si deve realizzare.
    `
  },
}

import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoJavascriptTypescriptIntro extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            javascript_typescript: file(relativePath: { eq: "courses/courses-logos/typescript.png" }) { ...thumb },

            review_javascript_1: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_1.png" }) { ...imgTrainingBig },
            review_javascript_2: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_2.png" }) { ...imgTrainingBig },
            review_javascript_10: file(relativePath: { eq: "courses/courses-reviews/javascript/review_javascript_10.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/courses-mode-images/javavascript-typescript-mode1.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/javavascript-typescript-mode2.png" }) { ...imgMd },
            pricing_bg: file(relativePath: { eq: "courses/pricing/javascript.png" }) { ...imgMd },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#f4de50',
    separatorHeaderColor2: '#317ac6',
    separatorColor1: '#f4de50',
    separatorColor2: '#f4d17a',
    buttonColor: '#317ac6',
    panelBackground1: '#317ac6',
    iconColor1: '#666',       // summary
    iconColor2: '#317ac6',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso Javascript ES6 / Typescript',
    title: 'JAVASCRIPT <strong>ES6</strong><br> & <strong>TYPESCRIPT</strong> INTRO',
    shortTitle: 'JAVASCRIPT <strong>ES6</strong><br> & <strong>TYPESCRIPT</strong> INTRO',
    subtitle: 'Un corso introduttivo sulle funzionalità di JavaScript e Typescript che ogni sviluppatore front-end dovrebbe conoscere!',
    image: 'javascript_typescript',
    difficulty: 'Principiante',
    duration: '8 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Questo corso fornisce una solida base sui costrutti principali del linguaggio Javascript ES6/7/8 fornendo un\'introduzione a Typescript allo scopo di sfruttare al massimo le potenzialità di framework e librerie front-end di ultima generazione, come Angular, React, Vue, Svelte o nel caso di Web Components.'},
    /*{ title: 'INTRODUZIONE', text: 'Una corso su Javascript ES6/7/8 e un\'introduzione sulle feature di Typescript che ogni sviluppatore dovrebbe conoscere per sfruttare al meglio framework e librerie Javascript di ultima generazione come Angular, React, Vue, Svelte ...'},*/
    { title: 'OBIETTIVO', text: 'Dalla versione ES6 (aka ES2015) di Javascript sono state introdotte nuove API, costrutti e funzionalità che possono risultare di difficile lettura ad un primo sguardo.<br /> Conoscere il linguaggio in modo approfondito è di fondamentale importanza per ottenere le massime performance dai moderni framework front-end, creare un codice più conciso, manutenibile e, da non sottovalutare, risulterà decisamente utile per comprendere il codice scritto dai vostri colleghi o presente oramai in articoli tecnici o nella documentazione di moltissime librerie.'},
    { title: 'REQUISITI', text: 'Conoscere un qualsiasi linguaggio di programmazione'},
  ],
  metadata: [
  ],
  carousels: {
    main: null,
    reviews: [
      {img: 'review_javascript_10', desc: ''},
      {img: 'review_javascript_1', desc: ''},
      {img: 'review_javascript_2', desc: ''},
    ],
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Principiante' },
    { icon: faCode, label: 'Versione Javascript:', value: 'ES6+' },
    { icon: faCode, label: 'Versione Typescript:', value: '4+' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa breve video, esamineremo le :',
    video1: 'Il8AQFkY4iE',
    bgColor: '#ddb657',
    items: [
      { text: '"var" vs "let"'},
      { text: 'Block scope'},
      { text: 'Function scope'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode3',
      title: 'TEORIA',
      text: 'Sessioni di teoria per comprendere limiti e potenzialità del linguaggio: utilizzo del "this", immutabilità, ByReference vs ByValue, ...'
    },
    colCenter: null,
    colRight: {
      img: 'mode1',
      title: 'SINGOLI ESEMPI',
      text: 'Decine di esempi pratici, casi d’uso, tip&tricks e sugar syntax delle principali funzionalità del linguaggio',
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'JAVASCRIPT ES2015 (aka ES6)'},
      { value: 'Javascript ES2015'},
      { value: 'Compiler, Transpiler e tool: Babel, Typescript e Webpack'},
      { value: 'var vs let vs const: function scope vs block scope'},
      { value: 'Template Literals'},
      { value: 'Arrow syntax'},
      { value: 'Destructuring'},
      { value: 'Object.assign() e Object concise syntax'},
      { value: 'Rest e Spread operator'},
      { value: 'Immutability vs Mutability'},
      { value: 'Array methods: find, map, filter, reduce, some, every …'},
      { value: 'ES6 modules & imports'},
      { value: 'type="module" vs "nomodule"'},
      { value: 'Utilizzo di fetch per la comunicazione con REST API'},
      { value: 'Promises e operazioni asincrone'},
      { value: 'Utilizzo di async await'},
      { value: 'Classi e ereditarietà in ES6'},
    ],
    colRight: [
      { type: 'title', value: 'TYPESCRIPT'},
      { value: 'Typescript vs ES6' },
      { value: 'Optional Chaining' },
      { value: 'Classes, Private vs Public' },
      { value: 'Types: interface vs class vs type' },
      { value: 'Utility types: Partial, Omit, Pick ...' },
      { value: 'Complex custom Types' },
      { value: 'Assertion Type' },
      { value: 'Alias Types' },
      { value: 'Union Type' },
      { value: 'Intersection Type' },
      { value: 'Type Guards & Differentiating Types' },
      { value: 'User-Defined Guards' },
      { value: 'Type Predicate, "typeof", "in" operator, " e diverse altre tecniche per identificare il tipo di dato' },
      { value: 'Introduzione ai Generics' },
/*
      { type: 'title', value: 'BONUS: DOM & CSS'},
      { value: 'Manipolazione DOM' },
      { value: 'CSS Selectors' },
      { value: 'Creazione Griglie e Layout con Flexbox' },
      { value: 'Introduzione a CSS Grid per la creazione di layout' },
      { value: 'Utilizzo di framework CSS come Bootstrap' },*/
    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '8 ore'},
      { label: 'CheatSheet Javascript ES6', extra: 'PDF formato A4'},
      { label: 'Slides Javascript ES6', extra: 'PDF formato A4'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Accesso al playground ES6 (snippet inclusi)', extra: 'on-line'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: 'Su22DYfFymM',
    teaser1Text: `
Ciao,
mi chiamo Fabio Biondi, sono Google Developer Expert in web technologies.
<br>
<br>
Spesso quando chiedo agli sviluppatori che frequentano i miei corsi, principalmente su Angular e React,
se conoscono Javascript mi rispondono di si.
Tuttavia, non appena inizio a fargli qualche domande, mi rendo conto che invece non sono affatto preparati.
<br>
<br>
Se il vostro obiettivo è quello di utilizzare framework e librerie front-end moderne,
questo corso Vi fornirà una solida base su Javascript, e vi sarà utile non solo per scrivere
un codice più leggibile ed efficiente ma, da non sottovalutare, anche per essere in grado di
comprendere il codice dei vostri colleghi più preparati o semplicemente
per poter leggere documentazione e articoli che ormai danno per scontato molti concetti.
<br>
<br>
In questo corso vi parlerò quindi delle features e delle moltissime novità introdotte nelle ultime release
del linguaggio Javascript (da ES6 in poi) con una breve introduzione su Typescript.
Concetti indispensabili ormai per lavorare con i moderni framework
e librerie front-end attualmente disponibili sul mercato, come Angular, React, Vue, SvelteJs, Web Components
e molti altri.
<br>
<br>
Di cosa parleremo?
<br>
<br>
Dopo una breve introduzione sui transpiler, compilatori e Webpack esploreremo essenzialmente la sintassi e i
costrutti fondamentali del linguaggio.
<br>
<br>
Inizialmente saranno affrontate tematiche fondamentali per un utilizzo corretto del linguaggio, dai comuni
problemi legati al this, alle diffenze di un linguaggio che funziona by reference e non by value e
tratteremo, inoltre, il concetto di immutabilità, fondamentale per incrementare le performance in framework
e librerie come Angular o React... e alla base di Redux, uno state manager utilizzato nella maggior parte
 delle Single Page Applicazioni moderne per la creazione di architetture complesse e manutenibili.
<br>
<br>
Ma la maggior parte della giornata sarà dedicata alla comprensione dei nuovi costrutti offerti
dal linguaggio Javascript come template literals, destructuring, arrow syntax, i nuovi metodi disponibili
per array e oggetti, spread operator, Object.assign, moduli e le varie sintassi per gli import,
classi e ereditarietà e molto altro.
Moltissimi esempi pratici e casi d'uso molti dei quali realizzati attraverso degli strumenti
che ho sviluppato per la formazione tramite i quali Vi farò inoltre capire cosa avviene
dietro le quinte quando il codice viene compilato, un aspetto secondo me spesso sottovalutato ma
di fondamentale importanza per comprendere realmente il codice che stiamo scrivendo.
<br>
<br>
Parleremo poi di Typescript, un superset di ES6 realizzato da Microsoft, alla base di framework
come Angular ma utilizzato ormai moltissimo anche nella community React, Vue e in molte altre librerie
o framework,  affrontando tematiche come tipizzazione, Generics, interfacce, decoratori.
<br>
<br>
Inoltre, dato che i miei corsi sono sempre tarati e personalizzati sulla base del know-how dei partecipanti,
se quest'ultimi non hanno esperienza nello sviluppo di applicazioni web, perché ad esempio si
occupano principalmente di mobile o server, una piccola parte del corso sarà dedicata alla manipolazione
del DOM, all'utilizzo di framework CSS e alla creazione di griglie e layout attraverso
due degli strumenti CSS che ritengo fondamentali oggi giorno: flexbox e CSS Grid.
    `
  },

}

import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoAngularForms extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_rxjs: file(relativePath: { eq: "courses/courses-logos/angular-rxjs.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/angular-core-concepts-course-diagram.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-rxjs-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-rxjs-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-rxjs-mode3.png" }) { ...imgMd },
            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#eb178e',
    separatorHeaderColor2: '#5f2c88',
    separatorColor1: '#eb178e',
    separatorColor2: '#5f2c88',
    buttonColor: '#eb178e',
    panelBackground1: '#eb178e',
    iconColor1: '#eb178e',       // summary
    iconColor2: '#eb178e',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Un corso dedicato ai Reactive Forms in Angular',
    title: 'ANGULAR 13 & <strong>RXJS 7</strong>',
    shortTitle: 'ANGULAR 13<br />& <strong>RXJS 7</strong>',
    subtitle: 'Apprendere le fondamenta del paradigma funzionale reattivo, comprendere a fondo la libreria RxJS e sfruttarne il potenziale in applicazioni Angular',
    image: 'angular_rxjs',
    difficulty: 'Avanzato',
    duration: '12 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Un corso intensivo per acquisire i concetti fondamentali sul paradigma funzionale reattivo e sull’utilizzo della libreria RxJS in Angular.' },
    { title: '', text: 'RxJS è, infatti, una delle dipendenze più importanti di Angular, alla base della maggior parte delle funzionalità del framework che ruotano attorno al concetto di Observable: reactive forms, Router, HttpClient, HTTP interceptors, @Output EventEmitter, state manager come NGRX, solo per citarne alcuni.' },
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OVERVIEW PROGRAMMA', text: `

Il corso è organizzato in due moduli: la prima parte del corso è dedicata alla comprensione e l’utilizzo della libreria RxJS in vanilla JavaScript mentre la seconda parte sarà interamente dedicata all'integrazione con Angular:</br>

</br>• Si comprenderanno a fondo le <strong>fondamenta della libreria</strong>: <code>Observable</code>, <code>Observer</code>, <code>Subscription</code>, le differenti tipologie di <code>Subject</code>, notifiche <code>next, error, complete</code>, lettura dei marble diagrams, con esempi pratici e casi d’uso reali in vanilla JS ma soprattutto in applicazioni Angular.</br> 
</br>• Esempi pratici per la comprensione e l’utilizzo di moltissimi <strong>operatori pipeaple e di creazione</strong> forniti da RxJS.</br>
</br>• Decine di snippet per sfruttare RxJS nel processo di <strong>comunicazione con il server</strong> tramite il servizio <code>HttpClient</code>: <code>forkJoin, combineLatest, withLatestFrom, switchMap</code> e <code>mergeMap</code> sono solo alcuni degli operatori che andremo ad analizzare e sfruttare per gestire diverse casistiche real-world.</br>
</br>• Corretta <strong>gestione degli errori</strong> tramite operatori quali catchError e throwError</br>
</br>• Gestione processo di <strong>autenticazione, protezione route tramite guards, HTTP interceptor, protezione DOM tramite creazione di structural directives</strong>, utilizzando un approccio idiomatico, sfruttando RxJS e il paradigma reattivo</br>
</br>• Utilizzo di RxJS e dei pipeable operators con i <strong>Reactive Forms</strong></br>
</br>• <strong>Approfondimento su Subject</strong>: come funzionano, potenzialità e le differenze tra le quattro tipologie: <code>Subject, BehaviorSubject, AsyncSubject, ReplaySubject</code>.</br>
</br>• Gestire lo <strong>stato applicativo e creare architetture Angular con RxJS</strong>, l’utilizzo di Subject sfruttando il motore di dependency injection.</br>
    
    `},
    { title: 'OBIETTIVO DEL CORSO', text: 'Un corso intensivo indispensabile per comprendere le fondamenta di RxJS e della programmazione reattiva rivolto a tutti gli sviluppatori che vogliono sfruttarne il potenziale all’interno dei propri progetti Angular.<br> Un corso propedeutico, inoltre, all\'apprendimento di NGRX, uno state manager per Angular ispirato a Redux, che espone uno stato reattivo e che fa ampio uso di RxJS. \n'},
    { title: 'REQUISITI', text: 'La prima parte del corso è dedicata alla comprensione e all\'utilizzo della libreria RxJS (in vanilla JavaScript) mentre nella seconda parte moltissimi esempi saranno dedicati all\'integrazione con Angular.' },
    { text: 'Per la prima parte di corso, interamente dedicata ad RxJS e JavaScript, è sufficiente conoscere le principali funzionalità di JavaScript ES6: template literals, arrow syntax, destructuring, array methods (ad es. map, filter, find, reduce), spread operator e utilizzo dell\'immutabilità.' },
    { text: 'Per comprendere gli argomenti trattati nella seconda parte del corso, invece, è necessario possedere una discreta conoscenza del framework Angular e in particolar modo: direttive incluse nel framework; utilizzo di Angular Router; creazione componenti custom; creazione servizi e utilizzo del motore di dependency injection; comunicazione con il server con HttpClient e, possibilmente, aver già integrato un processo di autenticazione in applicazioni Angular (login, gestione token, HTTP interceptor, Router Guards)  '},
  ],
  metadata: [

  ],
  carousels: {
    main: null,
    reviews: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '12 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Intermedio' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione RxJS:', value: '7.5+' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questo video:',
    video1: 'DLLrqik2RTg',
    bgColor: '#5f2c88',
    items: [
      { text: 'effettuare chiamate HTTP sequenziali in cui la seconda chiamata dipende dal risultato della precedente\n'},
      { text: 'Creazione custom form "Rate" control'},
      { text: 'Implementazione di ControlValueAccessor'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Approfondimento su API, tecniche e funzionalità del framework. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'

    },
    colCenter: {
      img: 'mode2',
      title: 'LIVE CODING',
      text: 'La maggior parte degli argomenti sono esposti tramite sessioni di live coding, affrontando scenari reali e creando codice riutilizzabile',

    },
    colRight: {
      img: 'mode3',
      title: 'SOURCE CODE',
      text: 'Al termine del corso avrete a disposizione moltisissi esempi e snippet di codice riutilizzabili in applicazioni reali',
    }
  },
  program: {
    colLeft: [

      { type: 'title', value: 'Introduzione a RxJS'},
      { value: 'Introduzione al paradigma funzionale reattivo.'},
      { value: 'Imperative vs Reactive programming'},
      { value: 'Observable vs Observer'},
      { value: '<code>next</code>, <code>error</code> and <code>complete</code>'},
      { value: 'Pull vs Push'},
      { value: 'Hot vs Cold Observable'},
      { value: 'Unicast vs Multicast'},
      { value: 'Subscription'},
      { value: 'subscribe e/o <code>async</code> pipe'},
      { value: 'Operatori di Creazione vs Pipeable operators'},
      { value: 'Marble Diagrams'},
      { value: 'Higher Order Observables: switchMap vs mergeMap vs concatMap vs exhaustMap'},
      { value: 'Creare e gestire sequenze di observables'},
      { value: 'Debug'},
      { value: 'Bad & Good practices'},

      { type: 'title', value: 'RxJS Operators: esempi pratici'},
      { value: 'Creation operators'},
      { value: 'Filtering operators'},
      { value: 'Combination operators'},
      { value: 'Transformation operators'},
      { value: 'Higher Order operators'},
      { value: 'Conditional operators'},
      { value: 'Error Handling operators'},
      { value: 'Multicasting operators'},

      { type: 'title', value: 'Creare custom RxJS Operators'},
      { value: 'Creare operatori custom in JavaScript'},
      { value: 'Creare operatori custom in TypeScript'},
      { value: 'OperatorFunction vs MonoTypeOperatorFunction'},

    ],
    colRight: [

      { type: 'title', value: 'RXJS & Angular: esempi pratici HttpClient'},
      { value: 'bad (& good) practices in RxJS'},
      { value: 'Operatori RXJS indispensabili: <code>map</code>, <code>filter</code>, <code>reduce</code> e molti altri '},
      { value: '<code>Async</code> Angular pipe'},
      { value: 'Nested HTTP request with <code>switchMap</code>'},
      { value: '<code>switchMap</code> ed emissione valori multipli'},
      { value: '<code>switchMap</code> e salvataggio dei risultati parziali'},
      { value: 'Richieste HTTP simultanee con l\'operatore <code>forkJoin</code>'},
      { value: '<code>switchMap</code> vs <code>mergeMap</code> vs <code>concatMap</code> vs <code>exhaustMap</code>'},
      { value: '<code>reduce</code> and <code>scan</code> operators'},
      { value: 'Gestione errori e operatore <code>catchError</code>'},
      { value: '<code>retry</code> operator'},
      { value: 'Esempi di utilizzo con forms, router, pipes, directives, ...'},



      { type: 'title', value: 'Multicasting'},
      { value: 'Unicast vs Multicast: differenze'},
      { value: 'Operatori <code>share</code>, <code>publish</code>, <code>multicast</code>' },
      { value: 'Le differenti tipologie di Subject'},
      { value: 'Subject & Angular services: gestione stato applicativo'},
      { value: 'Esempi real-world in Angular'},


      { type: 'title', value: 'Autenticazione e sicurezza in RxJS'},
      { value: 'Gestione dell\'intero processo di autenticazione in RxJS'},
      { value: 'State management in RxJS'},
      { value: 'Gestione Stati derivati'},
      { value: 'login e logout'},
      { value: 'Gestione token JWT e stato autenticazione con <code>Subject</code> '},
      { value: 'Router Guard e Http Interceptor: utilizzo idiomatico di RxJS'},
      { value: 'Protezione DOM e gestione ruoli tramite direttive strutturali'},


      { type: 'title', value: 'RXJS & Angular: Reactive Forms'},
      { value: 'Integrazione XHR con i Form tramite operatori'},
      { value: 'Applicare operatori RxJS a FormControl e FormGroup'},
      { value: 'Integrazione 3rd party API e utilizzo di operatori RxJS (ad es. l\'operatore <code>withLatestFrom</code>'},


    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16 ore (2g)'},
      { label: 'Slides ed Esempi', extra: 'Accesso Area Studenti'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: null,
    teaser1Text: null
  },
}



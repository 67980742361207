import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoAngularNgrx extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            ngrx_angular: file(relativePath: { eq: "courses/courses-logos/angular-ngrx-full.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/ngrx-01.jpeg" }) { ...imgTrainingBig },
            carouselIntro2: file(relativePath: { eq: "courses/courses-carousel/ngrx-02.jpeg" }) { ...imgTrainingBig },
            carouselIntro3: file(relativePath: { eq: "courses/courses-carousel/ngrx-03.jpeg" }) { ...imgTrainingBig },
            carouselIntro4: file(relativePath: { eq: "courses/courses-carousel/ngrx-04.jpeg" }) { ...imgTrainingBig },

            carousel2_1: file(relativePath: { eq: "courses/courses-carousel/ngrx-dev-tool1.png" }) { ...imgTrainingBig },
            carousel2_2: file(relativePath: { eq: "courses/courses-carousel/ngrx-dev-tool2.png" }) { ...imgTrainingBig },
            carousel2_3: file(relativePath: { eq: "courses/courses-carousel/ngrx-dev-tool3.png" }) { ...imgTrainingBig },

            review_1: file(relativePath: { eq: "courses/courses-reviews/angular-ngrx/review_redux_ngrx_10.png" }) { ...imgTrainingBig },
            review_2: file(relativePath: { eq: "courses/courses-reviews/angular-ngrx/review_redux_ngrx_11.png" }) { ...imgTrainingBig },
            review_3: file(relativePath: { eq: "courses/courses-reviews/angular-ngrx/review_redux_ngrx_12.png" }) { ...imgTrainingBig },


            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode3.png" }) { ...imgMd },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#F9E4F0',
    separatorHeaderColor2: '#E1A2EC',
    separatorColor1: '#412846',
    separatorColor2: '#E1A2EC',
    buttonColor: '#E1A2EC',
    panelBackground1: '#E1A2EC',
    iconColor1: '#ab3fcc',       // summary
    iconColor2: '#E1A2EC',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso State Manager & Data Architectures in Angular & NGRX',
    title: 'STATE MANAGER & <br /><span>DATA ARCHITECTURES</span> <br/>\nCON <strong>ANGULAR</strong> e <strong>NGRX</strong>13',
    shortTitle: 'DATA ARCHITECTURES <br />IN <strong>ANGULAR</strong><span style="color: #dd0031">13</span> e <strong>NGRX</strong><span style="color: #ab3fcc"></span>',
    subtitle: 'Un corso intensivo su Angular e NGRX , uno state manager reattivo per la creazione di architetture solide e scalabili',
    image: 'ngrx_angular',
    difficulty: 'Avanzato',
    duration: '12 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Angular fornisce gli strumenti fondamentali per sviluppare un’intera Single Page Application. Tuttavia, le attività che il front-end deve svolgere sono sempre più complesse e le interfacce utente (UI), di conseguenza, stanno diventando sempre più sofisticate: gestione di un data-flow molto articolato, componenti che devono rimanere in sync tra di loro, integrazione unit test, refactoring e debug sono solo alcune delle attività ricorrenti che uno sviluppatore front-end deve affrontare quotidianamente. <br /><br />Redux, libreria nata per requisiti complessi in contesti quali Facebook, nasce con l’obiettivo di separare nettamente gli aspetti architetturali dalla user interface, semplificando la gestione dello stato applicativo e rendendo il codice molto più manutenibile, testabile e scalabile, grazie a diversi strumenti offerti dalla libreria e estensioni utilissime per il browser, come i Redux Dev Tools.' },
    { title: 'COS\'È <strong>NGRX</strong>',
      text: `NGRX è una libreria ispirata a Redux creata per gestire lo stato applicativo in applicazioni Angular e ne condivide diversi aspetti: azioni, reducers e un singolo store.
<br />Rappresenta ormai uno "standard de facto" tra gli state manager disponibili nell'ecosistema Angular ed è ormai utilizzato in moltissimi contesti corporate ed enterprise.
<br /><br />
In NGRX, i dati sono esposti sotto forma di Observable (quindi fa largo uso di RxJS) tramite selezioni dello stato e fornisce una moltitudine di best practice e utility per isolare ogni attività: azioni, effetti, modifica allo stato (reducer) e selezione stato (selectors).
<br /><br />
La UI risulterà quindi totalmente "stupida" e la maggior parte della business logic sarà quindi gestita da NGRX:  operazioni asincrone (effects), (type safed) actions, gestione stato (reducer), selettori con funzionalità di memoization (selectors), lazy loading store, export/import store, time travel debugging, sincronizzazione dello store con il router, serializzazione dello store, sync con localStorage, undo/redo e molto altro.
` },
    { title: 'SOMMARIO ARGOMENTI', text: `
Si fornirà, innanzitutto, una solida base sui concetti fondamentali tramite l'utilizzo slide, diagrammi e sessioni di teoria per comprendere la base del pattern Redux e il funzionamento di NGRX.
<br><br>Per consolidare i concetti acquisiti dopo la teoria, saranno descritte e affrontate le casistiche e gli scenari più comuni tramite sessioni di live-coding in cui si svilupperanno snippet di codice molto semplici e pratici non solo su NGRX ma anche su RXJS, gli operatori e i costrutti indispensabili che è necessario conoscere per poter sfruttare al massimo la libreria. 
<br><br>Si svilupperà, infine, un'applicazione completa ricca di esempi utili e anche molto particolari: creazione di store complessi, gestione della user interface e degli errori tramite specifici reducer, operazioni CRUD, relazioni tra porzioni di stato, gestione side-effects (XHR, Router e LocalStorage, azioni multiple, ...) , autenticazione JWT, gestione router e protezione delle view, HTTP interceptors, integrazione reactive forms, creazione direttive strutturali per la protezione del DOM e molto altro.
`
    },
    { title: 'WORKSHOP', text: `Il corso prevede circa 4 ore di workshop in cui gli studenti svilupperanno un'applicazione CRUD, applicando best practice e sfruttando il pattern Redux e la libreria NGRX.
`
    },

  ],
  description2: [
    { title: 'COSA SONO I REDUX DEV TOOLS?', text: 'Utilizzando Redux o NGRX sarà possibile utilizzare una fantastica estensione per i browser tramite la quale monitorare ogni fase della tua applicazione, semplificando le operazioni di manutenzione e debug: history azioni, modifiche allo stato applicativo, differenze tra stato precedente e successivo, time travel debugging, export e import di uno snapshot dello stato e molto altro.' },
  ],
  metadata: [
    { title: 'OBIETTIVO DEL CORSO', text: 'Creare applicazioni Angular scalabili, testabili e semplici da mantenere utilizzando NGRX per la gestione dello stato applicativo, applicando una netta separazione tra architettura dati e presentational layer. <br /> Al termine del corso il partecipante sarà, quindi, in grado di introdurre NGRX nei propri progetti Angular e di sfruttarne al massimo il potenziale.' },
    { title: 'REQUISITI', text: 'Per comprendere gli argomenti trattati nel corso è necessario avere una buona conoscenza delle seguenti funzionalità del framework Angular: componenti custom, moduli, dependency injection, router e lazy loading. Nel caso di intenda prima approfondire questi argomenti consiglio di frequentare il corso <a href="../corso-angular-mastering/">Mastering Angular</a>. <br>Pur essendo un argomento affrontato durante il corso, data la sua complessità, consiglio inoltre di documentarsi sul concetto di programmazione reattiva e in particolar modo sulla libreria RxJS e l\'utilizzo dei suoi operatori. Nel caso ti interessasse approfondire questa tematica, guarda il programma del corso  <a href="../corso-angular-and-rxjs/">Angular & RxJS</a>  ' },
    { title: 'VIDEO', text: 'Nel video seguente, tratto dal mio canale <a href="https://www.youtube.com/user/BiondiFabio" target="_blank">YouTube</a>, descrivo brevemente l\'importanza di utilizzare uno state manager e i vantaggi che introduce:' },
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1', desc: 'Un abuso della dependency injection può generare confusione nel progetto, limitare le performance e creare comportamenti anomali dovuto ad uno stato unpredictable'},
      {img: 'carouselIntro2', desc: 'Gestire il flusso dati attraverso uno state manager permette di creare una UI totalmente stateless / "stupida", semplice da mantenere e testare'},
      {img: 'carouselIntro3', desc: 'NGRX permette di organizzare e gestire in modo granulare tutto il ciclo di vita dello stato applicativo'},
      {img: 'carouselIntro4', desc: '... e di organizzare queste fasi in specifici costrutti, utilizzando convenzioni e best practice universali, ormai consolidate'}
    ],
    description2: [
      {img: 'carousel2_1', desc: 'Monitorare il flusso di azioni in ogni fase del ciclo di vita della tua applicazione tramite funzionalità di time travel debugging'},
      {img: 'carousel2_2', desc: 'Lo stato applicativo e le sue variazioni sono consultabili per ogni azione tramite il comodissimo inspector'},
      {img: 'carousel2_3', desc: 'Il pannello "diff" sarà di aiuto per visualizzare le variazioni tra stato precedente e attuale'},
    ],
    reviews: [
      {img: 'review_1', desc: ''},
      {img: 'review_2', desc: ''},
      {img: 'review_3', desc: ''},
    ],  },
  videos: {
    teaser1: 'A_DlNgouGj8',
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '12 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Avanzato' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione NGRX:', value: '13.x' },
    /*{ icon: faVideo, label: 'Tipologia:', value: 'Video Corso' },*/
    { icon: faUser, label: 'Tipologia:', value: 'in aula e da remoto' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa lezione:',
    video1: 'A_DlNgouGj8',
    items: [
      { text: 'Punto 1'},
      { text: 'Punto 2'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Approfondimento su API, tecniche e funzionalità di NGRX. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'
    },
    colCenter: {
      img: 'mode3',
      title: 'LIVE CODING',
      text: 'Moltissimi esempi pratici con sessioni di live-coding sulle principali funzionalità di NGRX gestendo differenti scenari e casi d\'uso',

    },
    colRight: {
      img: 'mode2',
      title: 'WORKSHOP',
      text: 'Gli studenti realizzeranno un\'applicazione CRUD applicando Best Practices e utilizzando la libreria NGRX'
    }
  },
  program: {
    colLeft: [

      { type: 'title', value: 'Redux fundamentals'},
      { value: 'Principi fondamentali di Redux'},
      { value: 'Immutable State in Javascript'},
      { value: 'Pure vs Inpure functions'},
      { value: 'Redux core concepts: store, reducers, actions'},

      { type: 'title', value: 'NGRX fundamentals'},
      { value: 'Introduzione tecnica alla libreria'},
      { value: 'Costrutti, funzionalità e API principali'},
      { value: 'Installare le librerie necessarie'},
      { value: 'Reactive Store e Observable'},
      { value: 'Modellare lo store con <code>StoreModule</code>'},
      { value: 'Modificare lo stato tramite reducer'},
      { value: 'Selectors e memoization: selezionare porzioni di stato'},
      { value: 'Debugging e Redux Chrome Dev tools'},
      { value: 'Esempi e casi d\'uso reali'},

      { type: 'title', value: 'Impostare progetti NGRX'},
      { value: 'Organizzazione progetti scalabili e manutenibili in Angular'},
      { value: 'Data architectures in Angular e NGRX'},
      { value: 'Sviluppo modulare: custom <code>ngModules</code> Core, Shared e Features'},
      { value: 'Approccio component-based'},
      { value: 'Stateless architectures'},
      { value: 'Containers vs Presentational components'},
      { value: 'Sviluppo Real World Applications in ANGULAR / NGRX: gestione CRUD, autenticazione, profilo, forms complessi, protezione DOM, router e molto altro'},



      { type: 'title', value: 'Autenticazione, Sicurezza e NGRX'},
      { value: 'Processo di autenticazione JWT'},
      { value: 'Modifica profilo utente'},
      { value: 'Router Guards: creare sezioni protette da login'},
      { value: 'HTTP Interceptors'},
      { value: 'Protezione del DOM tramite custom structural directives, ad es. <code>*ifLogged</code>'},
      { value: 'AutoLogin e persistenza dati tramite localStorage'},

    ],
    colRight: [

      { type: 'title', value: 'Reducer & Selectors'},
      { value: 'Stato globale con <code>forRoot</code> '},
      { value: 'Gestione stato "lazy" tramite l\'utilizzo di <code>forFeature</code>'},
      { value: 'Modifica dello stato tramite reducer'},
      { value: 'Il metodo <code>createReducer</code> in NGRX'},
      { value: 'Nested reducers con <code>ActionReducerMap</code>'},
      { value: 'Selectors semplici e compositi'},
      { value: '<code>createSelector</code> vs <code>createFeatureSelector</code>'},
      { value: 'MetaReducers'},

      { type: 'title', value: 'Actions & Effects'},
      { value: 'Time travel debugging'},
      { value: 'Typed Safed Actions con <code>createAction</code>'},
      { value: 'Il metodo <code>createAction</code>'},
      { value: 'Gestione side effects con "NGRX Effects"'},
      { value: 'Il metodo <code>createEffect</code>'},
      { value: 'Gestione applicazioni CRUD, router e localStorage'},
      { value: 'Avviare azioni multiple negli effects'},
      { value: 'Effetti condizionali sulla base dello store'},

      { type: 'title', value: 'Router Store'},
      { value: 'Installazione e configurazione NGRX Router'},
      { value: 'Sincronizzazione router con stato applicativo'},
      { value: 'Navigazione'},
      { value: 'Creazione custom Actions per il router'},
      { value: 'Router Selectors'},
      { value: 'Protezione route con guardie, NGRX e l\'utilizzo di JWT'},
      { value: 'Gestione router con Lazy Loading'},


      { type: 'title', value: 'Reactive Forms, UI & NGRX'},
      { value: 'Integrazione Reactive Forms'},
      { value: 'Sincronizzazione Form con lo stato applicativo'},
      { value: 'Utilizzo di FormBuilder, FormControl, FormGroup'},
      { value: 'Gestione elenchi dati con FormArray'},
      { value: 'Gestione User Interface tramite NGRX (ad es. apertura e gestione pannelli) '},


    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '12 ore'},
      { label: 'Workshop studenti', extra: 'In aula'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'CheatSheet NGRX ', extra: 'PDF formato A4'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyText: 'RICHIEDI PREVENTIVO',
      buyLink: null,
      target: '_scrollTo',
      scrollToID: '#pricingPanel'
    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}

import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class ReactReduxCorso extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            react_ts_redux: file(relativePath: { eq: "courses/courses-logos/react-ts-redux.png" }) { ...thumb },    

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#61dafb',
    separatorHeaderColor2: '#764abc',
    separatorColor1: '#61dafb',
    separatorColor2: '#764abc',
    buttonColor: '#30b3d7',
    iconColor1: '#666',       // summary
    iconColor2: '#764abc',    // faq
    panelBackground1: '#764abc',
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso di formazione su React, Redux, Typescript',
    title: 'REACT / REDUX TOOLKIT<br />(& TYPESCRIPT)',
    shortTitle: 'REACT / REDUX TOOLKIT<br />(& TYPESCRIPT)',
    subtitle: 'Una giornata di formazione sull\'ultima versione di Redux, utilizzando Typescript, Redux Toolkit e RTK Query',
    image: 'react_ts_redux',
    difficulty: 'Intermedio',
    duration: '8 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: `
React fornisce gli strumenti fondamentali per l'organizzazione della user interface. 
Tuttavia, le attività che il front-end deve svolgere sono sempre più complesse e le interfacce utente (UI), 
di conseguenza, stanno diventando sempre più sofisticate: gestione di un data-flow molto articolato, 
componenti che devono rimanere in sync tra di loro, integrazione unit test, refactoring e debug sono 
solo alcune delle attività ricorrenti che uno sviluppatore front-end deve affrontare quotidianamente.` 
    },

 { title: 'REDUX', text: `
 Redux nasce con l’obiettivo di separare nettamente gli aspetti architetturali dalla user interface, 
 semplificando la gestione dello stato applicativo e rendendo il codice molto più manutenibile, testabile e scalabile
  grazie alla possibilità di isolare e tracciare ogni fase del ciclo di vita dell'applicazione.` },

    { title: 'REDUX TOOLKIT', text: `
 In questo corso utilizzeremo <strong>Redux Toolkit</strong>, una libreria ufficiale realizzata dal team Redux allo scopo di:
  <li>semplificarne l'utilizzo di Redux in React</li>
  <li>ridurre draticamente la quantità di codice e dipendenze</li>
  <li>rendere Redux "opionated", definendo regole e buone pratiche </li>
  
` },

    { title: 'PERCHÈ TYPESCRIPT & HOOKS?', text: `
 In questo corso utilizzeremo Typescript e faremo ampio uso degli hook forniti dalle ultime versioni sia di React che Redux.
 <br><strong>Perché Typescript e gli hook?</strong>
  <br>Per saperne di più ti invito a leggere il programma del corso <a href="../corso-react-typescript/">   "React & Typescript (with hooks)" </a> 
 `
    },

    { title: 'OBIETTIVO DEL CORSO', text: 'Integrare e utilizzare lo state manager Redux in applicazioni React utilizzando Redux Toolkit e Typescript'},
    { title: 'REQUISITI', text: 'Familiarità con l’utilizzo di React e Typescript. Conoscere, quindi, gli argomenti affrontati durante il corso <a href="../corso-react-typescript">React & Typescript (with hooks)</a>.' },
  ],
  metadata: [

  ],
  carousels: {
    main: null,
    reviews: null
  },
  videos: {
    teaser1: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8-12 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Intermedio' },
    { icon: faCode, label: 'Versione React:', value: '18.x' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },

  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'Un semplice esempio in Angular per il caricamento di dati tramite REST API e la loro visualizzazione sfruttando alcuni degli strumenti offerti dal framework:',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#c3002f',
    items: [
      { text: 'Direttive del framework: ngIf e ngFor'},
      { text: 'HttpClient e comunicazione con REST API'},
      { text: 'Creazione custom types tramite l\'utilizzo di interfaccce'},
    ]
  },
  mode: null,
  program: {
    colLeft: [



      { type: 'title', value: 'FUNDAMENTALS' },
      { value: 'TEORIA: Principi fondamentali di Redux', },
      { value: 'Immutable State', },
      { value: 'Pure vs Inpure functions', },
      { value: 'Containers vs Presentational components', },
      { value: 'Concetto di "Store"', },
      { value: 'Cosa sono i "reducer"', },
      { value: '"Actions"', },
      { value: 'Side "Effects"', },
      { value: 'State "Selectors" e memoization', },
      { value: 'Introduzione ai Redux DevTool', },
      { value: 'Data Architecture e Stateless UI', },
      { value: 'La libreria React-Redux ' },
      { value: 'La libreria Redux Toolkit ' },
      { value: 'Immutability vs ImmerJS' },
      { value: 'Debugging e Potenzialità dei Redux Dev tools', },
      { value: 'Organizzazione progetti enterprise', },
    ],
    colRight: [


      { type: 'title', value: 'REDUX' },
      { value: 'LIVE CODING: tutti i concetti sono descritti con sessioni di live coding e la creazione di diversi esempi e micro applicazioni', },
      { value: 'Creazione progetti React con Redux & Redux Toolkit', },
      { value: 'Configurare lo Store e i Redux DevTools', },
      { value: 'Utilizzo del componente Provider', },
      { value: 'Gestione stato tramite Reducer e combineReducer' },
      { value: 'Redux Hooks: useSelector and useDispatch' },
      { value: 'Dispatch di azioni'},
      { value: 'Selectors semplici e compositi (\'useSelector\') '},
      { value: 'Creare reducer con l\'hook \'createReducer\'', },
      { value: 'Gestire lo stato con l\'hook \'createSlice\'', },
      { value: 'Differenti tecniche per la gestione degli errori' },
      { value: 'Side Effect, XHR e operazioni asincrone con Redux Thunk', },

      { type: 'title', value: 'ADVANCED TOPICS' },
      { value: 'createAsyncThunk' },
      { value: 'extraReducers' },
      { value: 'Custom Middlewares' },
      { value: 'Introduzione a Redux Observable & RxJS' },
      { value: 'Entities: createEntityAdapter ' },
      { value: 'Introduzione a RTK Query' },
    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '8-12 ore'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
    //campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}

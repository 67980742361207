import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import css from './index.module.css';
import cn from 'classnames';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SeparatorWave } from "../components/course/separator-wave/SeparatorWave"
import { Separator } from "../components/course/course-ui-components/SeparatorPadding"
import { Footer } from "../components/shared/Footer"
import { faChartBar, faIndustry, faClock, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TimeLineVert } from "../components/shared/TimelineVert"
import { CourseInstructorProfile } from "../components/course/course-instructor-profile/CourseInstructorProfile"
import { CarouselCarouselReview } from "../components/course/course-carousel-reviews/CourseCarouselReview"
import { COURSE_INFO as A_NGRX } from "./corso-angular-ngrx"
import { COURSE_INFO as A_CC } from "./corso-angular-core-concepts"
import { COURSE_INFO as A_FORMS } from "./corso-angular-reactive-forms"
import { COURSE_INFO as GIT_BEGINNER } from "./corso-git-beginners"
import { COURSE_INFO as JS_TS } from "./corso-javascript-typescript"
import { COURSE_INFO as A_UI} from "./corso-angular-ui"
import { COURSE_INFO as A_MM } from "./corso-angular-mastering"
import { COURSE_INFO as A_RXJS } from "./corso-angular-and-rxjs"
import { COURSE_INFO as R_TS } from "./corso-react-typescript"
import { COURSE_INFO as R_TS_REDUXTOOLKIT } from "./corso-react-redux-typescript"
/*import { COURSE_INFO as R_CC } from "./corso-react-core-concepts"
import { COURSE_INFO as R_M } from "./corso-react-mastering"
import { COURSE_INFO as R_REDUX } from "./corso-react-redux"*/
import { COURSE_INFO as A_FUNDAMENTALS } from "./videocorso-angular-fundamentals"
import { CourseNewsletterLink } from "../components/course/course-newsletter/CourseNewsletterLink"


export const COURSES = {
  misc: {
    title: 'CORSI',
    items: [
      {...JS_TS, url: '/corso-javascript-typescript/'},
      {...GIT_BEGINNER, target: "_blank", url: 'https://videocorsi.fabiobiondi.io/corsi/git-fundamentals', videoCourse: true},
    ]
  },
  angular: {
    title: 'ANGULAR',
    items: [
      // {...GIT_BEGINNER, url: '/corso-git-beginners/'},
      // {...JS_TS, url: '/corso-javascript-typescript/'},
      {...A_CC, url: '/corso-angular-core-concepts/'},
      {...A_MM, url: '/corso-angular-mastering/'},
      {...A_UI, url: '/corso-angular-ui/'},
      {...A_RXJS, url: '/corso-angular-and-rxjs/'},
      {...A_FORMS, url: '/corso-angular-reactive-forms/'},
      /*{ url: null},*/
      {...A_NGRX, url: '/corso-angular-ngrx/'},
    ]
  },

  react: {
    title: 'REACT',
    items: [
      {...R_TS, url: '/corso-react-typescript/'},
      {...R_TS_REDUXTOOLKIT, url: '/corso-react-redux-typescript/'},
      { header: { shortTitle: 'NEXT FUNDAMENTALS<br />REACT / TYPESCRIPT', subtitle: 'Un corso su NextJS (con TypeScript) per creare siti web e applicazioni performanti e SEO-Friendly! Creato da Fabio Biondi e Adriano Grimaldi', image: 'next_fundamentals' }, target: "_blank", url: 'https://videocorsi.fabiobiondi.io/corsi/next-fundamentals', videoCourse: true},

      { url: null},
      // {...R_CC, url: '/corso-react-core-concepts/'},
      // {...R_M, url: '/corso-react-mastering/'},
      // {...R_REDUX, url: '/corso-react-redux/'},
    ]
  },
  videos: {
    angular_fundamentals: { ...A_FUNDAMENTALS, url: '/videocorso-angular-fundamentals/' }
  },
  reviews: [
    {img: 'review_10', desc: ''},
    {img: 'review_11', desc: ''},
    {img: 'review_1', desc: ''},
    {img: 'review_4', desc: ''},
    {img: 'review_6', desc: ''},
    {img: 'review_8', desc: ''},
  ],
}

const PAGE_INFO = {
  theme: {
    /*separatorHeaderColor1: '#F9E4F0',
    separatorHeaderColor2: '#E1A2EC',
    separatorColor1: '#412846',
    separatorColor2: '#E1A2EC',*/
    buttonColor: '#dd0031',
    panelBackground1: '#f7f7f7',
    /*iconColor1: '#ab3fcc',       // summary
    iconColor2: '#E1A2EC',    // faq
    footerVisible: true,
    navbarVisible: true,*/
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
}

export default class IndexPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            git_fundamentals: file(relativePath: { eq: "courses/courses-logos/git-fundamentals.png" }) { ...thumb },
            next_fundamentals: file(relativePath: { eq: "courses/courses-logos/next-fundamentals.png" }) { ...thumb },
            javascript_typescript: file(relativePath: { eq: "courses/courses-logos/typescript.png" }) { ...thumb },
            angular_core_concepts: file(relativePath: { eq: "courses/courses-logos/angular-cc.png" }) { ...thumb },
            angular_mastering: file(relativePath: { eq: "courses/courses-logos/angular-mastering.png" }) { ...thumb },
            angular_forms: file(relativePath: { eq: "courses/courses-logos/angular-forms.png" }) { ...thumb },
            angular_ui: file(relativePath: { eq: "courses/courses-logos/angular-ui.png" }) { ...thumb },
            ngrx_angular: file(relativePath: { eq: "courses/courses-logos/angular-ngrx-full.png" }) { ...thumb },
            angular_rxjs: file(relativePath: { eq: "courses/courses-logos/angular-rxjs.png" }) { ...thumb },
            react_core_concepts: file(relativePath: { eq: "courses/courses-logos/react-core-concepts.png" }) { ...thumb },
            react_mastering: file(relativePath: { eq: "courses/courses-logos/react-mastering.png" }) { ...thumb },
            react_redux: file(relativePath: { eq: "courses/courses-logos/react-redux.png" }) { ...thumb },
            react_ts: file(relativePath: { eq: "courses/courses-logos/react-ts.png" }) { ...thumb },
            react_ts_redux: file(relativePath: { eq: "courses/courses-logos/react-ts-redux.png" }) { ...thumb },    
            angular_fundamentals: file(relativePath: { eq: "courses/courses-logos/angular-fundamentals.png" }) { ...thumb },

            review_10: file(relativePath: { eq: "courses/courses-reviews/general/review_generic_10.png" }) { ...imgTrainingBig },
            review_11: file(relativePath: { eq: "courses/courses-reviews/general/review_generic_11.png" }) { ...imgTrainingBig },
            review_1: file(relativePath: { eq: "courses/courses-reviews/general/review_general_1.png" }) { ...imgTrainingBig },
            review_4: file(relativePath: { eq: "courses/courses-reviews/general/review_general_4.png" }) { ...imgTrainingBig },
            review_6: file(relativePath: { eq: "courses/courses-reviews/general/review_general_6.png" }) { ...imgTrainingBig },
            review_8: file(relativePath: { eq: "courses/courses-reviews/general/review_general_8.png" }) { ...imgTrainingBig },

            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <Compo {...this.props}  data={data} /> }
      />
    )
  }
}


const Compo = (props) => (
  <Layout>
    <SEO title="Corsi di Formazione Angular, React, Javascript" />
{/*

    <div className="container-fluid">
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        emulateTouch
        autoPlay
        stopOnHover
        interval={3000}
        showStatus={false}
        show
        showThumbs={false}
        showIndicators={false}
      >
        <div>
          <img src={props.data.carouselIntro1.childImageSharp.fluid.src} alt=""/>
          <p className="legend">
            bla bla
          </p>
        </div>

      </Carousel>

    </div>

    <Separator size="xxl"/>

*/}


{/*
    <SeparatorWave bg1="#CB2B38"  bg2="#B32435" />
*/}
    <SeparatorWave bg1="#dedede"  bg2="#efefef" />


    <div className="container-big">

      <h1 className="text-header-main-title center light">CORSI <span className="bold">FONDAMENTALI</span></h1>
      <br/>

      <div className="row">
        <CourseRow {...props} row={COURSES.misc} linkColor={'#000'} />
        {/*<CourseItem {...props } {...COURSES.videos.angular_fundamentals}  linkColor={'#222'} />*/}
      </div>


      {/*      <h1 className="text-header-main-title center light"><span style={{color: '#dd0031'}} className="bold">VIDEO</span> CORSI </h1>*/}

      {/*Visualizzare il corso Angular fundamentals su una nuova riga*/}
      {/*
      <div className="row">

        <div className="col-sm-3 col-md-4"></div>
            <CourseItem {...props } {...COURSES.videos.angular_fundamentals}></CourseItem>
        <div className="col-sm-3 col-md-4"></div>
      </div>*/}


    </div>

    <SeparatorWave bg1="#CB2B38"  bg2="#B32435" />


    <div className="container-big">

      <h1 className="text-header-main-title center light">CORSI <span style={{color: '#dd0031'}} className="bold">ANGULAR</span></h1>
      <br/>

      <div className="row">
        <CourseRow {...props} row={COURSES.angular} linkColor={'#dd0031'} />
        {/*<CourseItem {...props } {...COURSES.videos.angular_fundamentals}  linkColor={'#222'} />*/}
      </div>


{/*      <h1 className="text-header-main-title center light"><span style={{color: '#dd0031'}} className="bold">VIDEO</span> CORSI </h1>*/}

      {/*Visualizzare il corso Angular fundamentals su una nuova riga*/}
      {/*
      <div className="row">

        <div className="col-sm-3 col-md-4"></div>
            <CourseItem {...props } {...COURSES.videos.angular_fundamentals}></CourseItem>
        <div className="col-sm-3 col-md-4"></div>
      </div>*/}


    </div>

    <SeparatorWave bg1="#61DAFB"  bg2="#30B3D7" />

    <div className="container-big">
      <h1 className="text-header-main-title center light">CORSI <span className="bold" style={{color: '#30b3d7'}}>REACT</span></h1>

      <br/>
      <div className="row">
        <CourseRow {...props} row={COURSES.react} linkColor={'#30b3d7'}  />
      </div>


    </div>

    <SeparatorWave bg1="#dedede"  bg2="#efefef" />

    {/*<AltriCorsi />*/}

    {/*CORSI PERSONALIZZATI*/}
    <TimeLineVert />

    <div className="container-big">

      <Separator size="xl"/>

      <CourseInstructorProfile {...props}/>

    </div>



    <div style={{ backgroundColor: '#222', width: '100%', color: 'white', padding: '60px 0'}}>
      <div className="container-big">

        <div className="row" style={{padding: '20px 20px 20px 20px'}}>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="text-box-title">
              <span className="text-badged">
                <FontAwesomeIcon icon={faUsers} fixedWidth={true} style={{width: 30, fontSize: 30}} /> 2500+
              </span>

              <Separator size="md"/>
              <span className="text-badged">
                <FontAwesomeIcon icon={faIndustry} fixedWidth={true} style={{width: 30, fontSize: 30}} /> 200+
              </span>
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-8 text-paragraph-title">

            <div>
              <Separator size="md"/>
              <div>Centinaia di aziende hanno già scelto i miei corsi</div>
              <div style={{fontSize: 12, color: '#ccc'}}>
                (Banca Italia, Accenture, Engineering, Presidenza del Consiglio, Cineca, Seac, Team System, Beghelli, Zucchetti, Green Peace, Phoenix Informatica Bancaria, Fatture in Cloud, Insiel Mercato, GPI, Links Management & Technologies, Estra Luce & Gas e molti altri)
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>


    <div className="container-big">
      <Separator size="xl"/>

      <CarouselCarouselReview
        {...props}
        items={COURSES.reviews}
      />
    </div>

    {/*  <li><Link to="/corso-angular-core-concepts/">Corso Angular Core Concepts</Link></li>
    <li><Link to="/corso-angular-ngrx/">Video Corso NGRX</Link></li>

*/}
    <Separator size="xl"/>

    <div id="newsletter">
      <CourseNewsletterLink
        newsletter={PAGE_INFO.newsletter}
        theme={PAGE_INFO.theme}
      />
    </div>

    <Footer visible />

  </Layout>
)

const CourseRow = (props) => {
  return (
    props.row.items.map((item, index) => {
      return item.url ?
        <CourseItem {...props} {...item} key={index} /> :
        <div className="col-xs-12 col-sm-6 col-md-4"></div>
    })
  )
}


const CourseItem = props => {
  return (

      <div className="col-xs-12 col-sm-6 col-md-4">
        <Separator size="md"/>

        <Link to={props.url} style={{color: '#222'}}>
          <h2 className="text-title-md center"
               dangerouslySetInnerHTML={{ __html: props.header.shortTitle }}
          />
        </Link>

        <Separator size="sm"/>

        {
          props.videoCourse &&
            <div className="row middle-xs">

              <div
                style={{ color: "#222", textAlign: "center" }}
                className={cn("col-xs-12 ", css.center_xs_right_big)}>
                <a href={props.url} target="_blank" style={{ color: "#222" }}>
                  <img src={props.data[props.header.image].childImageSharp.fluid.src}
                       style={{maxHeight: 100 }} alt={props.header.image}/>
                </a>
              </div>

            </div>}

        {
        !props.videoCourse &&
          <div className="row middle-xs">
            <div className={cn("col-xs-12 col-sm-5 col-md-6", css.center_xs_right_big)}>
              <Link to={props.url} style={{ color: "#222" }}>
                <img src={props.data[props.header.image].childImageSharp.fluid.src}
                     style={{ width: "100%" }} alt={props.header.image}/>
              </Link>
            </div>

            <div className="col-xs-12 col-sm-7  col-md-6">
              <div className={cn("row middle-xs")}>
                <div className={css.center_xs_right_big}>
                  <FontAwesomeIcon icon={faClock} fixedWidth={true}
                                   style={{ width: 20, marginRight: 10, fontSize: 20 }}/>
                  {props.header.duration}
                </div>
              </div>

              <div className="row middle-xs">
                <div className={css.center_xs_right_big}>
                  <FontAwesomeIcon icon={faChartBar} fixedWidth={true}
                                   style={{ width: 20, marginRight: 10, fontSize: 20 }}/>
                  {props.header.difficulty}
                </div>
              </div>
            </div>
        </div>
        }

        <Separator size="sm"/>

        <div className="center">
          {
            !props.target  &&
              <Link to={props.url} style={{color: '#222'}}>
                {props.header.subtitle}
                <Separator size="sm"/>

                {/*<FontAwesomeIcon icon={faExternalLinkSquareAlt} fixedWidth={true} style={{width: 20, fontSize: 20, color: props.linkColor}} />*/}

                <span
                  style={{backgroundColor: props.linkColor}}
                  className={css.discover_more_button}>SCOPRI DI PIÙ</span>
              </Link>
          }

          {
            props.target === '_blank' &&
              <a href={props.url} target="_blank" style={{ color: '#222' }}>
                {props.header.subtitle}
                <Separator size="sm"/>

                {/*<FontAwesomeIcon icon={faExternalLinkSquareAlt} fixedWidth={true} style={{width: 20, fontSize: 20, color: props.linkColor}} />*/}

                <span
                  style={{ backgroundColor: '#666' }}
                  className={css.discover_more_button}
                >
                  VAI al SITO VIDEOCORSI
                </span>
              </a>
          }

            <Separator size="lg"/>


        </div>
        <Separator size="sm"/>

        <div
          className="show-on-xs"
          style={{border: '1px dashed #ccc'}} />

       {/* <div className="center">
          <button className="button button1 xs">
            PROGRAMMA
          </button>
        </div>*/}

      </div>
  )
}

export const AltriCorsi = () => {
  return (
    <div className="container-big">
      <h1 className="text-header-main-title center light">ALTRI <span className="bold">ARGOMENTI</span></h1>
      <br/>

      <div className="center">
        Altre tematiche su cui, io e i miei colleghi, possiamo fornirvi supporto, mentoring e formazione:
      </div>
      <br/>

      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <br/>
          <h4 className="bold">MICROSOFT & JS</h4><br/>
          <li>Azure DevOps e App service</li>
          <li>Azure Cognitive Services</li>
          <li>Graph API: integrazione Office in SPA</li>
          <li>Creazione AddIn per Microsoft Office in React</li>
          <li>React Fluent UI</li>
          <li>FastJS</li>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <br/>
          <h4 className="bold">LIBRERIE / FRAMEWORKS FRONT-END</h4><br/>
          <li>Recoil JS: state manager per React</li>
          <li>Gatbsy / NextJS</li>
          <li>Stencil JS / Web Components</li>
          <li>VueJS</li>
          <li>Cypress</li>
          <li>D3.js</li>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <br/>
          <h4 className="bold">MISC</h4><br/>
          <li>Firebase & AngularFire</li>
          <li>HTML 5 canvas e CreateJS</li>
          <li>Animations in GSAP  / TweenMax</li>
          <li>Utilizzo di Chart: HighChart, ChartJS, …</li>
          <li>NodeJS / socket.io / Express / NestJS</li>
          <li>Flutter</li>
        </div>
      </div>

      <Separator size="xl"/>


    </div>
  )
}

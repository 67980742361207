import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class ReactCoreConcepts extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            react_ts: file(relativePath: { eq: "courses/courses-logos/react-ts.png" }) { ...thumb },
            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/react-ts-esempio-screen.jpg" }) { ...imgTrainingBig },
            carouselIntro2: file(relativePath: { eq: "courses/courses-carousel/react-ts-esempio-code.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#61dafb',
    separatorHeaderColor2: '#30b3d7',
    separatorColor1: '#61dafb',
    separatorColor2: '#30b3d7',
    buttonColor: '#30b3d7',
    panelBackground1: '#30b3d7',
    iconColor1: '#666',       // summary
    iconColor2: '#30b3d7',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso di formazione su React e Typescript',
    title: 'REACT FUNDAMENTALS<br />in TYPESCRIPT',
    shortTitle: 'REACT FUNDAMENTALS<br />in TYPESCRIPT<br />',
    subtitle: 'Un corso di formazione su React e Typescript personalizzabile: dalle basi ai pattern più recenti fino ad ottimizzazioni e tecniche di state management',
    image: 'react_ts',
    difficulty: 'Principiante',
    duration: '16/32 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: `
      <a href="https://reactjs.org/" target="_blank"> React</a>, sviluppato da Facebook, è attualmente una delle librerie Javascript più amate e utilizzate per lo sviluppo di Single Page Application.
       <br /><br />Dalla metà del 2019 React è decisamente cambiato rispetto alle precedenti versioni, per 3 motivi principali: 
       <br /><br />
       1) <strong>Typescript</strong>: che è ormai adottato dalla maggior parte degli sviluppatori React, soprattutto in contesti enterprise.
Tuttavia, anche nel caso avessi già esperienza in Typescript, non è sempre banale la sua integrazione in applicazioni React e, di conseguenza, questo corso vi potrà essere molto utile.
<br /><br />
2) <strong>Hooks</strong>: In React 16.8 sono stati introdotti ufficialmente gli "hooks" che hanno stravolto il modo di scrivere applicazioni e componenti React, abbandonando quasi esclusivamente l'utilizzo dei componenti "class-based".
<br /><br />
3) <strong>Nuove API</strong>: il corso è aggiornato alla release 16.13+ di React e Typescript 3.7.x.

     `},
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 16/32 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 16/32 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'PERCHÈ UTILIZZARE TYPESCRIPT?', text: `
• <strong>Supportato dalla CLI</strong> ufficiale di React (CRA)
<br />• <strong>Static Type System</strong>
<br />• <strong>Solido e sicuro</strong>: riduce i bug causati da errate assunzioni sul tipo 
<br />• Funzionalità di <strong>completamento</strong> più precise e <strong>refactoring</strong> più sicuro negli IDE
<br />• Aiuta a <strong>documentare il codice</strong> 
<br />• <strong>Errori a compile-time</strong> 
<br />• Utilizzato ormai in Angular, React, Vue, Node, ...
<br />• <strong>Popolare</strong>: è ormai uno dei linguaggi più utilizzati al mondo
<br />• <strong>La community React</strong> lo ama e sempre più team lo stanno adottando 
<br />• <strong>Features</strong>: introduce nuove funzionalità molto più celermente rispetto a Javascript  
<br />• <strong>Microsoft</strong>: componenti React / TS possono essere utilizzati in Microsoft Power Apps, Dynamics e Office 365
    `},
    { title: 'PERCHÈ USARE GLI HOOKS?', text: `
• <strong>100% backwards-compatible</strong>
<br />• <strong>Completely opt-in</strong>: puoi utilizzarli o meno insieme a componenti di "vecchia" generazione
<br />• <strong>React Trend</strong>: il team Facebook sta chiaramente andando in questa direzione e non ha più senso utilizzare le classi
<br />• <strong>Riutilizzo della logica</strong>: semplificano la creazione di componenti complessi isolando la business logic (forms, fetch, redux, drag'n'drop ...)
<br />• <strong>Stateful Functional Components</strong>: introducono la gestione dello stato in componenti funzionali
<br />• <strong>Nuovo Component lifecycle</strong>: introducono il ciclo di vita nei componenti funzionali e lo semplificano rispetto alle classi
<br />• <strong>Meno componenti</strong>: riducono il numero di componenti dell'applicazione e si evita l'utilizzo di "<em>higher order components</em>" e "<em>render props</em>"

    ` },
  ],
  description2: null,

  metadata: [
    { title: 'PUNTI DI FORZA DEL CORSO', text: `
In questo corso React, ho cercato di raccogliere i concetti fondamentali, le metodologie più utilizzate e i casi d'uso più frequenti che si presentano durante lo sviluppo di Single Page Application in React / Typescript.
<br /><br />• In <strong>breve tempo</strong> conoscerete le funzionalità più importanti delle ultime release della libreria per essere <strong>subito operativi e produttivi</strong>
<br /><br />• Puoi personalizzare la durata del corso (da 16 a 32 ore), scegliere quali argomenti affrontare (tra quelli disponibili nel programma) e la complessità con cui trattare le tematiche sulla base delle  necessità e preparzione del team

<br /><br />• Applicherete fin da subito <strong>metodologie e best practice</strong> allo scopo di creare codice scalabile e semplice da mantenere: approccio component-based, custom hooks, lazy loading, gestione sicurezza, utilizzo di Typescript, componenti riutilizzabili.
<br /><br />• Ho <strong>ridotto al minimo l'utilizzo di librerie esterne</strong>: per descrivere i concetti fondamentali della libreria utilizzeremo semplicemente la versione CSS di "Bootstrap" per il layout, "React Router DOM" per la creazione di applicazioni multiview e "classnames", una semplice utility per applicare dinamicamente classi CSS.
<br /><br />• Il corso è propedeutico all'utilizzo di Redux & Redux Toolkit. 
<br /><br />• Sono previste moltissime esercitazioni da effettuare durante e/o dopo il corso allo scopo di consolidare le conoscenze acquisite durante le lezioni. Saranno disponibili nell\'esclusiva area riservata a cui gli studenti avranno accesso anche dopo il termine del corso.  
<br /><br />

    ` },
    { title: 'REQUISITI', text: 'Familiarità con almeno un linguaggio di programmazione ad oggetti.<br /> È preferibile, ma non indispensabile, aver già utilizzato in precedenza HTML, CSS e avere un po\' di confidenza nella manipolazione del DOM in Javascript.' },

  ],
  carousels: {
    main: null,
    description2: null,
    reviews: null
  },
  videos: {
    teaser1: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16/32 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Principianti' },
    { icon: faCode, label: 'Versione React:', value: '18.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x' },
    { icon: faUser, label: 'Tipologia:', value: 'Corso in aula' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'Un semplice esempio in Angular per il caricamento di dati tramite REST API e la loro visualizzazione sfruttando alcuni degli strumenti offerti dal framework:',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#30b3d7',
    items: [
      { text: 'Direttive del framework: ngIf e ngFor'},
      { text: 'HttpClient e comunicazione con REST API'},
      { text: 'Creazione custom types tramite l\'utilizzo di interfaccce'},
    ]
  },
  mode: null,
  program: {
    colLeft: [
      { type: 'title', value: 'Hello React'},
      { value: 'Creazione progetto React '},
      { value: 'Utilizzo di JSX '},
      { value: 'template condizionali '},
      { value: 'Analisi dei file che compongono un progetto React'},
      { value: 'Installare librerie CSS: Bootstrap e FontAwesome '},
      { value: 'Utilizzo di CSS'},
      { value: 'Hello Custom Components '},
      { value: 'Typescript: tipizzazione dei componenti'},
      { value: 'Typescript: inferenza'},
      { value: 'TypeScript 3.7 TIP: optional chaining '},
      { value: 'Passare Proprietà ai componenti e tipizzazione '},
      { value: 'Passare template JSX come proprietà '},




      { type: 'title', value: 'Hooks, fetch e REST API, async-await'},
      { value: 'Introduzione alla gestione dello stato in functional components '},
      { value: 'Hook: gestione stato con "useState" '},
      { value: 'Hook: lifecycle con "useEffect" '},
      { value: 'Comunicazione server: "fetch" API e "Promise" '},
      { value: 'Comunicazione server: utilizzo della libreria "Axios"'},
      { value: '"Fetch & Axios" e utilizzo di "async-await" '},
      { value: 'Utilizzo di "useState" in children component '},
      { value: 'model & entities '},
      { value: 'Creazione di vari custom hook'},





      { type: 'title', value: 'MultiView Application con React Router'},
      { value: 'Perchè React Router'},
      { value: 'Installazione e utilizzo di React Router Dom '},
      { value: 'Definire le regole del router '},
      { value: 'Creare la navigation bar '},
      { value: 'Lazy Loading with Suspense '},
      { value: 'Route e Passaggio Parametri '},
      { value: 'Redirect e Default view'},
      { value: 'Hook: cambiare route con "useHistory" '},


      { type: 'title', value: 'CRUD App - Parte 1: liste e collezioni dati'},
      { value: 'Visualizzare una collezione di elementi '},
      { value: 'Component-based approach'},
      { value: 'Organizzare i componenti in moduli '},
      { value: 'Creare un REST mock server con json-server '},
      { value: 'Utilizzare l\'hook "useEffect per gestire il ciclo di vita dei componenti" '},
      { value: 'Gestione errori HTTP '},
      { value: 'Eliminare elementi dal componente children '},

      { type: 'title', value: 'CRUD App - Parte 2: form - add / edit'},
      { value: 'Creazione Form Gestione elementi: ADD, EDIT, RESET '},
      { value: 'Lista - aggiornare i dati di elementi selezionati'},
      { value: 'Creare custom hook per gestire business logic: stato e XHR '},
      { value: 'Approfondimento Approccio component-based '},
      { value: 'Refactoring - organizzare la struttura cartelle '},


      { type: 'title', value: 'Performance & Optimization'},
      { value: 'Utilizzo dei React Developer Tools'},
      { value: 'React Developer Tools: components'},
      { value: 'React Developer Tools: Profile'},
      { value: 'Ottimizzazione performance'},
      { value: 'Differenze tra fase render e commit'},
      { value: 'Utilizzo di useMemo'},
      { value: 'Utilizzo di useCallback'},
      { value: 'Utilizzo di React.memo'},


      { type: 'title', value: 'Dynamic Component Loading'},
      { value: 'Creare istanze di componenti a runtime' },
      { value: 'Utilizzare if, switch e ternary operator' },
      { value: 'Utilizzo di una dictionary' },
      { value: 'Utilizzo API: React createElement' },
      { value: 'Lazy Loading di componenti: React.lazy e Suspense' },



    ],
    colRight: [


      { type: 'title', value: 'JSX, styling components & tips'},
      { value: 'applicare classi CSS dinamicamente sulla base delle proprietà dei componenti'},
      { value: 'Applicare classi con l\'utility "classnames" '},
      { value: 'Template condizionali: operatore ternario VS "AND" operator '},
      { value: 'Utilizzo style inline '},
      { value: 'Inline styles and CSSProperties Type '},
      { value: 'Typescript: Utilizzare il destructuring per le proprietà '},
      { value: 'La proprietà "Children" '},
      { value: 'Organizzare il CSS in differenti files '},
      { value: 'Glocal CSS vs Local Scoped CSS '},
      { value: 'CSS Modules'},
      { value: 'Utilizzo della libreria "classnames"'},
      { value: 'Utilizzare SCSS nei propri progetti'},


      { type: 'title', value: 'Mouse events, callback props & stateless apps'},
      { value: 'Mouse events: utilizzo e gestione tipizzazione'},
      { value: 'Invocare funzioni in JSX'},
      { value: 'Dynamic templates'},
      { value: 'Passare "callback" come proprietà di un componente'},

      { type: 'title', value: 'Forms'},
      { value: 'Intro sui Form in React'},
      { value: '"useRef" hook - assegnare focus ad un campo di input '},
      { value: 'KeyboardEvent & uncontrolled forms '},
      { value: 'Controlled Form e gestione stato del form'},
      { value: 'Validare i form'},
      { value: 'Gestione stato form "error" e "dirty" '},
      { value: 'Creare form con Input, Checkbox, Select '},


      { type: 'title', value: 'React Hook Form'},
      { value: 'Utilizzo e configurazione della libreria'},
      { value: 'Creazione form'},
      { value: 'Validazione e gestione errori'},
      { value: 'Le utility fornite dalla libreria'},
      { value: 'Gestire form con nested object'},







      { type: 'title', value: 'Autenticazione e Sicurezza'},
      { value: 'Introduzione a JWT '},
      { value: 'Login '},
      { value: 'Persistenza Token con LocalStorage '},
      { value: 'Guards: rotezione Accesso Route '},
      { value: 'Creare componenti "speciali" per proteggere il DOM da accessi indesiderati'},
      { value: 'HTTP Interceptor, header e JWT'},
      { value: 'Redirects, default view e fallback'},

      { type: 'title', value: 'Reusable Components + Typescript Tips'},
      { value: 'Creare componenti riutilizzabili'},
      { value: 'Estendere le proprietà dei componenti con attributi del DOM'},
      { value: 'HTML attributes vs HTML Props '},
      { value: 'Utilizzo dei Generics'},
      { value: 'Component: Stateful vs Stateless Collapsable'},
      { value: 'Component: Static Map'},
      { value: 'Component: Custom Button'},
      { value: 'Component: TabBar'},
      { value: 'Introduzione: documentare componenti con Storybook'},

      { type: 'title', value: 'State management in React'},
      { value: 'Utilizzo di useState & useReducer per gestione stato globale'},
      { value: 'Drilling Props vs Composition'},
      { value: 'Diverse tecniche nell\'utilizzo delle Context API'},
      { value: 'Utilizzo di micro state management: Zustand, Valtio, Jotai'},
      { value: 'Differenze performance tra le diverse soluzioni'},

      { type: 'title', value: 'Environment Variables'},
      { value: 'Variabili d\'ambiente: production vs development' },
      { value: 'Creazione custom environment variables' },
      { value: 'Tipizzare le variabili d\'ambiente: react-app-env.d.ts' },

    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16/32 ore'},
      { label: 'Accesso Area Studenti', extra: 'Slide & Esercizi'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}

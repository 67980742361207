import React from 'react';
import './TimelineVert.css';
import { Link } from "gatsby"
import { Separator } from "../course/course-ui-components/SeparatorPadding"

export const TimeLineVert = () => {
  return (
    <section id="timeline">
      <div className="text-box-title center">CORSI PERSONALIZZATI</div>
      <Separator size="xl"/>
      <div className="demo-card-wrapper">
        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>01</span>
            </div>
            <h2><span className="small">RICHIEDI</span>INFORMAZIONI</h2>
          </div>
          <div className="body">
            <p>
              Scegli i corsi che ti interessano tra quelli disponibili a catalogo.
              <br/>
              Hai dubbi o domande su quali tecnologie front-end dovresti adottare nel prossimo progetto?
              <br/>
              <br/>
              Utilizza il
              <Link to="forms/contact-form/?subject=1"> form di Contatto </Link> per
              ricevere informazioni, disponibilità e tariffe.
            </p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>02</span>
            </div>
            <h2><span className="small">PERSONALIZZA IL </span>PROGRAMMA</h2>
          </div>
          <div className="body">
            <p>
              Un percorso didattico tarato sui vostri obiettivi e competenze:
              <br/>
              <br/>
              <li><strong>BEGINNERS</strong>: vi seguirò passo-passo nella creazione del programma.</li>
              <li><strong>EXPERTS</strong>: organizzeremo una sessione di <em>code review</em> per valutare la vostra attuale preparazione e stilare un programma ad hoc per colmare eventuali lacune.</li>
            </p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>03</span>
            </div>
            <h2><span className="small">REMOTE / ON-SITE</span> LEZIONI</h2>
          </div>
          <div className="body">
            <p>
              Le lezioni possono essere erogate da remoto oppure presso la vostra sede.
              <br/>
              <br/>
              In ogni caso includeranno sessioni di studio, teoria e live-coding e, qualora richiesto, anche  esercitazioni per gli studenti, quiz e verifiche.
              <br/>
              {/*Le classi sono composte mediamente da 12 partecipanti (max 30) ma tale numero di partecipanti varia a seconda dell'argomento e attività: training, workshop, mentoring o eventi.*/}
            </p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>04</span>
            </div>
            <h2><span className="small">ESERCITAZIONI & MATERIALE</span>AREA STUDENTI</h2>
          </div>
          <div className="body">
            <p>Durante il corso e nei mesi successivi, tutti i partecipanti potranno accedere ad un’area riservata con contenuti costantemente aggiornati:
              <br/>
              <li>download codice sorgente</li>
              <li>slide, materiale e cheatsheet PDF</li>
              <li>esercitazioni per gli studenti</li>
            </p>
          </div>
        </div>


        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>05</span>
            </div>
            <h2><span className="small">ASSISTENZA E</span>SUPPORTO</h2>
          </div>
          <div className="body">
            <p>
              <li><Link to="forms/contact-form/?subject=5">Form contatti</Link></li>
              <li>community <span> </span>
                <a href="https://www.facebook.com/groups/angularjs.developer.italiani/" target="_blank">Angular</a>,<span> </span>
                <a href="https://www.facebook.com/groups/react.developer.italiani/" target="_blank">React</a>,<span> </span>
                <a href="https://www.facebook.com/groups/javascript.developer.italiani/" target="_blank">JavaScript</a></li>
              <li>Approfondimenti sul mio <a href="https://www.youtube.com/user/BiondiFabio" target="_blank">canale YouTube</a></li>
              <li>Live coding sul mio <a href="https://www.twitch.tv/fabio_biondi" target="_blank">canale Twitch</a></li>
              <li>Pagine <a href="https://www.facebook.com/groups/fabiobiondi.training" target="_blank">Facebook</a>, <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank">LinkedIn</a> e <a href="https://www.twitch.tv/fabio_biondi" target="_blank">Twitter</a></li>
            </p>


          </div>
        </div>


      </div>
    </section>
  )
};

